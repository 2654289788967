import React from 'react';
import AppLayout from '../../layout/app.layout';
import UsersMigration from '../../components/reports/users-migration.component';

const MigrationStatusView = () => {
  const pageTitle = 'Reports / Migration Status';

  return (
    <AppLayout pageTitle={pageTitle}>
      <UsersMigration />
    </AppLayout>
  );
};

export default MigrationStatusView;
