import React from 'react';
import PropTypes from 'prop-types';
import { useRoutes, Navigate } from 'react-router-dom';
import { VALID_ADMIN_ROLES } from '../config/const.config';
import ROUTE_PATH from '../config/routes.config';
import LoginView from '../views/auth/login.view';
import DashboardView from '../views/dashboard/dashboard.view';
import RealtimeDashboardView from '../views/dashboard/realtime-dashboard.view';
import ContentChannelsView from '../views/content/channels.view';
import ContentVideosView from '../views/content/videos.view';
import ContentPlaylistsView from '../views/content/playlists.view';
import SubscribersView from '../views/subscribers/subscribers.view';
import SMTPSettingsView from '../views/smtp/smtp-settings.view';
import AdminUsersView from '../views/admin-users/admin-users.view';
import BillingSubscriptionsView from '../views/billing/subscriptions.view';
import BillingPaymentsView from '../views/billing/payments.view';
import ReportsOverviewView from '../views/reports/overview.view';
import ReportsLiveUsersView from '../views/reports/live-users.view';
import ReportsSessionsView from '../views/reports/sessions.view';
import ReportsAppSessionsView from '../views/reports/app-sessions.view';
import ReportsActiveSubscribers from '../views/reports/active-subscribers.view';
import ReportsMigrationStatus from '../views/reports/migration-status.view';
import ReportsTrialSubscribers from '../views/reports/trial-subscribers.view';
import ReportsUsersCountOverview from '../views/reports/users-count-overview.view';
import PageNotFoundView from '../views/errors/not-found.view';
import LogoutView from '../views/auth/logout.view';

const AppRouter = ({ isLoggedIn, accessRole }) => {
  const routeMappings = [];

  if (isLoggedIn) {
    routeMappings.push({
      path: ROUTE_PATH.LOGIN,
      element: <Navigate to={ROUTE_PATH.DASHBOARD} />,
      exact: true,
    });

    routeMappings.push({
      path: ROUTE_PATH.DASHBOARD,
      element: <DashboardView />,
      exact: true,
    });

    routeMappings.push({
      path: ROUTE_PATH.REALTIME_DASHBOARD,
      element: <RealtimeDashboardView />,
      exact: true,
    });

    routeMappings.push({
      path: ROUTE_PATH.SUBSCRIBERS,
      element: <SubscribersView />,
      exact: true,
    });

    routeMappings.push({
      path: ROUTE_PATH.BILLING_SUBSCRIPTIONS,
      element: <BillingSubscriptionsView />,
      exact: true,
    });

    routeMappings.push({
      path: ROUTE_PATH.BILLING_PAYMENTS,
      element: <BillingPaymentsView />,
      exact: true,
    });

    routeMappings.push({
      path: ROUTE_PATH.REPORTS_OVERVIEW,
      element: <ReportsOverviewView />,
      exact: true,
    });

    routeMappings.push({
      path: ROUTE_PATH.REPORTS_LIVE_USERS,
      element: <ReportsLiveUsersView />,
      exact: true,
    });

    routeMappings.push({
      path: ROUTE_PATH.REPORTS_SESSIONS,
      element: <ReportsSessionsView />,
      exact: true,
    });

    routeMappings.push({
      path: ROUTE_PATH.REPORTS_APP_SESSIONS,
      element: <ReportsAppSessionsView />,
      exact: true,
    });

    routeMappings.push({
      path: ROUTE_PATH.REPORTS_MIGRATION_STATUS,
      element: <ReportsMigrationStatus />,
      exact: true,
    });

    routeMappings.push({
      path: ROUTE_PATH.REPORTS_ACTIVE_SUBSCRIBERS,
      element: <ReportsActiveSubscribers />,
      exact: true,
    });

    routeMappings.push({
      path: ROUTE_PATH.REPORTS_TRIAL_SUBSCRIBERS,
      element: <ReportsTrialSubscribers />,
      exact: true,
    });

    routeMappings.push({
      path: ROUTE_PATH.REPORTS_CHANNEL_ALL_USERS,
      element: <ReportsUsersCountOverview />,
      exact: true,
    });

    routeMappings.push({
      path: ROUTE_PATH.LOGOUT,
      element: <LogoutView />,
      exact: true,
    });

    if (
      [VALID_ADMIN_ROLES.SUPER_ADMIN, VALID_ADMIN_ROLES.ADMIN].includes(
        accessRole
      )
    ) {
      routeMappings.push({
        path: ROUTE_PATH.CONTENT_CHANNELS,
        element: <ContentChannelsView />,
        exact: true,
      });

      routeMappings.push({
        path: ROUTE_PATH.CONTENT_VIDEOS,
        element: <ContentVideosView />,
        exact: true,
      });

      routeMappings.push({
        path: ROUTE_PATH.CONTENT_PLAYLISTS,
        element: <ContentPlaylistsView />,
        exact: true,
      });

      routeMappings.push({
        path: ROUTE_PATH.SMTP_SETTINGS,
        element: <SMTPSettingsView />,
        exact: true,
      });
    }

    if (accessRole === VALID_ADMIN_ROLES.SUPER_ADMIN) {
      routeMappings.push({
        path: ROUTE_PATH.ADMIN_USERS,
        element: <AdminUsersView />,
        exact: true,
      });
    }
  }

  routeMappings.push({
    path: '*',
    element: isLoggedIn ? <PageNotFoundView /> : <LoginView />,
  });

  const routes = useRoutes(routeMappings);

  return routes;
};

AppRouter.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  accessRole: PropTypes.string.isRequired,
};

export default AppRouter;
