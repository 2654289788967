import CELL_DATA_TYPES from '../cell-types.config';

// Active subscribers columns configuration
export const ACTIVE_SUBSCRIBERS_COLUMNS_REVENUE = [
  {
    id: 'title',
    label: 'App Name',
    width: '10%',
    align: 'left',
    dataKey: 'title',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'total',
    label: 'Total',
    width: '5%',
    align: 'right',
    dataKey: 'total',
    sortable: true,
    type: CELL_DATA_TYPES.MONEY,
  },
  {
    id: 'compTotal',
    label: 'Comp Accounts',
    width: '5%',
    align: 'right',
    dataKey: 'compTotal',
    sortable: true,
    type: CELL_DATA_TYPES.MONEY,
  },
  {
    id: 'rokuMonthly',
    label: 'Roku Monthly',
    width: '5%',
    align: 'right',
    dataKey: 'rokuMonthly',
    sortable: true,
    type: CELL_DATA_TYPES.MONEY,
  },
  {
    id: 'rokyYearly',
    label: 'Roku Annual',
    width: '5%',
    align: 'right',
    dataKey: 'rokyYearly',
    sortable: true,
    type: CELL_DATA_TYPES.MONEY,
  },
  {
    id: 'firetvMonthly',
    label: 'Fire TV Monthly',
    width: '5%',
    align: 'right',
    dataKey: 'firetvMonthly',
    sortable: true,
    type: CELL_DATA_TYPES.MONEY,
  },
  {
    id: 'firetvYearly',
    label: 'Fire TV Annual',
    width: '5%',
    align: 'right',
    dataKey: 'firetvYearly',
    sortable: true,
    type: CELL_DATA_TYPES.MONEY,
  },
  {
    id: 'firemobileMonthly',
    label: 'Fire Mobile Monthly',
    width: '5%',
    align: 'right',
    dataKey: 'firemobileMonthly',
    sortable: true,
    type: CELL_DATA_TYPES.MONEY,
  },
  {
    id: 'firemobileYearly',
    label: 'Fire Mobile Annual',
    width: '5%',
    align: 'right',
    dataKey: 'firemobileYearly',
    sortable: true,
    type: CELL_DATA_TYPES.MONEY,
  },
  {
    id: 'androidtvMonthly',
    label: 'Android TV Monthly',
    width: '5%',
    align: 'right',
    dataKey: 'androidtvMonthly',
    sortable: true,
    type: CELL_DATA_TYPES.MONEY,
  },
  {
    id: 'androidtvYearly',
    label: 'Android TV Annual',
    width: '5%',
    align: 'right',
    dataKey: 'androidtvYearly',
    sortable: true,
    type: CELL_DATA_TYPES.MONEY,
  },
  {
    id: 'androidmobileMonthly',
    label: 'Android Mobile Monthly',
    width: '5%',
    align: 'right',
    dataKey: 'androidmobileMonthly',
    sortable: true,
    type: CELL_DATA_TYPES.MONEY,
  },
  {
    id: 'androidmobileYearly',
    label: 'Android Mobile Annual',
    width: '5%',
    align: 'right',
    dataKey: 'androidmobileYearly',
    sortable: true,
    type: CELL_DATA_TYPES.MONEY,
  },
  {
    id: 'appletvMonthly',
    label: 'Apple TV Monthly',
    width: '5%',
    align: 'right',
    dataKey: 'appletvMonthly',
    sortable: true,
    type: CELL_DATA_TYPES.MONEY,
  },
  {
    id: 'appletvYearly',
    label: 'Apple TV Annual',
    width: '5%',
    align: 'right',
    dataKey: 'appletvYearly',
    sortable: true,
    type: CELL_DATA_TYPES.MONEY,
  },
  {
    id: 'applemobileMonthly',
    label: 'Apple Mobile Monthly',
    width: '5%',
    align: 'right',
    dataKey: 'applemobileMonthly',
    sortable: true,
    type: CELL_DATA_TYPES.MONEY,
  },
  {
    id: 'applemobileYearly',
    label: 'Apple Mobile Annual',
    width: '5%',
    align: 'right',
    dataKey: 'applemobileYearly',
    sortable: true,
    type: CELL_DATA_TYPES.MONEY,
  },
  {
    id: 'webMonthly',
    label: 'Web Monthly',
    width: '5%',
    align: 'right',
    dataKey: 'webMonthly',
    sortable: true,
    type: CELL_DATA_TYPES.MONEY,
  },
  {
    id: 'webYearly',
    label: 'Web Annual',
    width: '5%',
    align: 'right',
    dataKey: 'webYearly',
    sortable: true,
    type: CELL_DATA_TYPES.MONEY,
  },
];

// Active subscribers columns configuration
export const ACTIVE_SUBSCRIBERS_COLUMNS_COUNTER = [
  {
    id: 'title',
    label: 'App Name',
    width: '10%',
    align: 'left',
    dataKey: 'title',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'total',
    label: 'Total',
    width: '5%',
    align: 'right',
    dataKey: 'total',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'compTotal',
    label: 'Comp Accounts',
    width: '5%',
    align: 'right',
    dataKey: 'compTotal',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'rokuMonthly',
    label: 'Roku Monthly',
    width: '5%',
    align: 'right',
    dataKey: 'rokuMonthly',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'rokyYearly',
    label: 'Roku Annual',
    width: '5%',
    align: 'right',
    dataKey: 'rokyYearly',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'firetvMonthly',
    label: 'Fire TV Monthly',
    width: '5%',
    align: 'right',
    dataKey: 'firetvMonthly',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'firetvYearly',
    label: 'Fire TV Annual',
    width: '5%',
    align: 'right',
    dataKey: 'firetvYearly',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'firemobileMonthly',
    label: 'Fire Mobile Monthly',
    width: '5%',
    align: 'right',
    dataKey: 'firemobileMonthly',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'firemobileYearly',
    label: 'Fire Mobile Annual',
    width: '5%',
    align: 'right',
    dataKey: 'firemobileYearly',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'androidtvMonthly',
    label: 'Android TV Monthly',
    width: '5%',
    align: 'right',
    dataKey: 'androidtvMonthly',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'androidtvYearly',
    label: 'Android TV Annual',
    width: '5%',
    align: 'right',
    dataKey: 'androidtvYearly',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'androidmobileMonthly',
    label: 'Android Mobile Monthly',
    width: '5%',
    align: 'right',
    dataKey: 'androidmobileMonthly',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'androidmobileYearly',
    label: 'Android Mobile Annual',
    width: '5%',
    align: 'right',
    dataKey: 'androidmobileYearly',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'appletvMonthly',
    label: 'Apple TV Monthly',
    width: '5%',
    align: 'right',
    dataKey: 'appletvMonthly',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'appletvYearly',
    label: 'Apple TV Annual',
    width: '5%',
    align: 'right',
    dataKey: 'appletvYearly',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'applemobileMonthly',
    label: 'Apple Mobile Monthly',
    width: '5%',
    align: 'right',
    dataKey: 'applemobileMonthly',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'applemobileYearly',
    label: 'Apple Mobile Annual',
    width: '5%',
    align: 'right',
    dataKey: 'applemobileYearly',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'webMonthly',
    label: 'Web Monthly',
    width: '5%',
    align: 'right',
    dataKey: 'webMonthly',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'webYearly',
    label: 'Web Annual',
    width: '5%',
    align: 'right',
    dataKey: 'webYearly',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
];

// Trial subscribers columns configuration
export const TRIAL_SUBSCRIBERS_COLUMNS = [
  {
    id: 'title',
    label: 'App Name',
    width: '15%',
    align: 'left',
    dataKey: 'title',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'total',
    label: 'Total',
    width: '5%',
    align: 'right',
    dataKey: 'total',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'rokuMonthly',
    label: 'Roku Monthly',
    width: '5%',
    align: 'right',
    dataKey: 'rokuMonthly',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'rokyYearly',
    label: 'Roku Annual',
    width: '5%',
    align: 'right',
    dataKey: 'rokyYearly',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'firetvMonthly',
    label: 'Fire TV Monthly',
    width: '5%',
    align: 'right',
    dataKey: 'firetvMonthly',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'firetvYearly',
    label: 'Fire TV Annual',
    width: '5%',
    align: 'right',
    dataKey: 'firetvYearly',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'firemobileMonthly',
    label: 'Fire Mobile Monthly',
    width: '5%',
    align: 'right',
    dataKey: 'firemobileMonthly',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'firemobileYearly',
    label: 'Fire Mobile Annual',
    width: '5%',
    align: 'right',
    dataKey: 'firemobileYearly',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'androidtvMonthly',
    label: 'Android TV Monthly',
    width: '5%',
    align: 'right',
    dataKey: 'androidtvMonthly',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'androidtvYearly',
    label: 'Android TV Annual',
    width: '5%',
    align: 'right',
    dataKey: 'androidtvYearly',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'androidmobileMonthly',
    label: 'Android Mobile Monthly',
    width: '5%',
    align: 'right',
    dataKey: 'androidmobileMonthly',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'androidmobileYearly',
    label: 'Android Mobile Annual',
    width: '5%',
    align: 'right',
    dataKey: 'androidmobileYearly',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'appletvMonthly',
    label: 'Apple TV Monthly',
    width: '5%',
    align: 'right',
    dataKey: 'appletvMonthly',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'appletvYearly',
    label: 'Apple TV Annual',
    width: '5%',
    align: 'right',
    dataKey: 'appletvYearly',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'applemobileMonthly',
    label: 'Apple Mobile Monthly',
    width: '5%',
    align: 'right',
    dataKey: 'applemobileMonthly',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'applemobileYearly',
    label: 'Apple Mobile Annual',
    width: '5%',
    align: 'right',
    dataKey: 'applemobileYearly',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'webMonthly',
    label: 'Web Monthly',
    width: '5%',
    align: 'right',
    dataKey: 'webMonthly',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'webYearly',
    label: 'Web Annual',
    width: '5%',
    align: 'right',
    dataKey: 'webYearly',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
];

// User count columns configuration
export const USER_COUNTS_COLUMNS = [
  {
    id: 'title',
    label: 'App Name',
    width: '15%',
    align: 'left',
    dataKey: 'title',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'total',
    label: 'Total',
    width: '9%',
    align: 'right',
    dataKey: 'total',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'roku',
    label: 'Roku',
    width: '9%',
    align: 'right',
    dataKey: 'roku',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'firetv',
    label: 'Fire TV',
    width: '9%',
    align: 'right',
    dataKey: 'firetv',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'firemobile',
    label: 'Fire Mobile',
    width: '10%',
    align: 'right',
    dataKey: 'firemobile',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'androidtv',
    label: 'Android TV',
    width: '9%',
    align: 'right',
    dataKey: 'androidtv',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'androidmobile',
    label: 'Android Mobile',
    width: '12%',
    align: 'right',
    dataKey: 'androidmobile',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'appletv',
    label: 'Apple TV',
    width: '9%',
    align: 'right',
    dataKey: 'appletv',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'applemobile',
    label: 'Apple Mobile',
    width: '10%',
    align: 'right',
    dataKey: 'applemobile',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
  {
    id: 'web',
    label: 'Web',
    width: '8%',
    align: 'right',
    dataKey: 'web',
    sortable: true,
    type: CELL_DATA_TYPES.NUMBER,
  },
];
