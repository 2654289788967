import axios from 'axios';
import prepareRequestOptions from '../utils/admin-apis.util';

export const getMigratedSubscribers = async (params = '') => {
  const options = prepareRequestOptions(
    `/admin/reports/migration${params}`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const getActiveSubscribers = async (params = '') => {
  const options = prepareRequestOptions(
    `/admin/reports/active-subscribers${params}`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const getTrialSubscribers = async (params = '') => {
  const options = prepareRequestOptions(
    `/admin/reports/trial-subscribers${params}`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};

export const getUsersCount = async (params = '') => {
  const options = prepareRequestOptions(
    `/admin/reports/users-count${params}`,
    'GET',
    null,
    true
  );

  const response = await axios(options);
  return response.data;
};
